html{
    scroll-behavior: initial !important;
}

*{
    text-decoration: none;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

a,a:hover{
    text-decoration: none;
    color: var(--white);
}


/* --- Common colors --- */
:root{
    --white: #ffffff;
    --white-900: rgba(255, 255, 255, 0.9);
    --white-800: rgba(255, 255, 255, 0.8);
    --white-700: rgba(255, 255, 255, 0.7);
    --white-600: rgba(255, 255, 255, 0.6);
    --white-500: rgba(255, 255, 255, 0.5);
    --white-400: rgba(255, 255, 255, 0.4);
    --white-300: rgba(255, 255, 255, 0.3);
    --white-200: rgba(255, 255, 255, 0.2);
    --white-100: rgba(255, 255, 255, 0.1);

    --celeste: #d2e5f1;
    --celeste-900: rgba(210, 229, 241, 0.9);
    --celeste-800: rgba(210, 229, 241, 0.8);
    --celeste-700: rgba(210, 229, 241, 0.7);
    --celeste-600: rgba(210, 229, 241, 0.6);
    --celeste-500: rgba(210, 229, 241, 0.5);
    --celeste-400: rgba(210, 229, 241, 0.4);
    --celeste-300: rgba(210, 229, 241, 0.3);
    --celeste-200: rgba(210, 229, 241, 0.2);
    --celeste-100: rgba(210, 229, 241, 0.1);
    
    --dark: #0e0f12;
    --dark-600: rgba(14, 15, 18, 0.6);

    --dark2:#14161a;

    --dark3:#2d3138;

    --gold-light: #ddc26e;

    --gold-dark: #c5861a;

    


    --darkblue: #0d1721;
    --darkblue-900: rgba(13, 23, 33, 0.9);
    --darkblue-800: rgba(13, 23, 33, 0.8);
    --darkblue-700: rgba(13, 23, 33, 0.7);
    --darkblue-600: rgba(13, 23, 33, 0.6);
    --darkblue-500: rgba(13, 23, 33, 0.5);
    --darkblue-400: rgba(13, 23, 33, 0.4);
    --darkblue-300: rgba(13, 23, 33, 0.3);
    --darkblue-200: rgba(13, 23, 33, 0.2);
    --darkblue-100: rgba(13, 23, 33, 0.1);

    --cyan:#00ffff;
    --cyan-900: rgba(0, 255, 255, 0.9);
    --cyan-800: rgba(0, 255, 255, 0.8);
    --cyan-700: rgba(0, 255, 255, 0.7);
    --cyan-600: rgba(0, 255, 255, 0.6);
    --cyan-500: rgba(0, 255, 255, 0.5);
    --cyan-400: rgba(0, 255, 255, 0.4);
    --cyan-300: rgba(0, 255, 255, 0.3);
    --cyan-200: rgba(0, 255, 255, 0.2);
    --cyan-100: rgba(0, 255, 255, 0.1);

    --electricblue:#00d5ff;
    --electricblue-900: rgba(0, 213, 255, 0.9);
    --electricblue-800: rgba(0, 213, 255, 0.8);
    --electricblue-700: rgba(0, 213, 255, 0.7);
    --electricblue-600: rgba(0, 213, 255, 0.6);
    --electricblue-500: rgba(0, 213, 255, 0.5);
    --electricblue-400: rgba(0, 213, 255, 0.4);
    --electricblue-300: rgba(0, 213, 255, 0.3);
    --electricblue-200: rgba(0, 213, 255, 0.2);
    --electricblue-100: rgba(0, 213, 255, 0.1);

    --azure:#007bff;
    --azure-900: rgba(0, 123, 255, 0.9);
    --azure-800: rgba(0, 123, 255, 0.8);
    --azure-700: rgba(0, 123, 255, 0.7);
    --azure-600: rgba(0, 123, 255, 0.6);
    --azure-500: rgba(0, 123, 255, 0.5);
    --azure-400: rgba(0, 123, 255, 0.4);
    --azure-300: rgba(0, 123, 255, 0.3);
    --azure-200: rgba(0, 123, 255, 0.2);
    --azure-100: rgba(0, 123, 255, 0.1);
}


/* --- Website Fonts --- */
@font-face {
    font-family: 'Montserrat';
    src: url("../fonts/Montserrat-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: 'Montserrat';
    src: url("../fonts/Montserrat-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: 'Montserrat';
    src: url("../fonts/Montserrat-SemiBold.ttf");
    font-weight: 600;
}


/* --- Body tag Css --- */
body{
    padding: 0;
    margin: 0;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    color: var(--celeste);
    background-color: var(--dark);
    /* overflow: hidden; */
    /* overflow-y: auto; */
}

.relative{
    position: relative;
}

.overflowhidden,
body.overflowhidden{
    overflow: hidden;
}


/* --- Web Loader Css --- */
.web-preloader {
    background-color: var(--dark);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2000;
    -webkit-background-size: 200% 200%;
       -moz-background-size: 200% 200%;
         -o-background-size: 200% 200%;
            background-size: 200% 200%;
    background-position: 0% 50%;
	-webkit-animation: preloader_gradient 2s infinite forwards;
	   -moz-animation: preloader_gradient 2s infinite forwards;
	     -o-animation: preloader_gradient 2s infinite forwards;
	        animation: preloader_gradient 2s infinite forwards;
}

@-webkit-keyframes preloader_gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-moz-keyframes preloader_gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-o-keyframes preloader_gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes preloader_gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.web-preloader span.web-inner {
    width: 200px;
    height: 200px;
    line-height: 200px;
    margin: auto;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    text-align: center;
    z-index: 0;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: var(--white);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.web-preloader span.web-inner img {
    width: 200px;
    height: 200px;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
    position: relative;
    -webkit-animation: gogoloader-2 5ms infinite;
       -moz-animation: gogoloader-2 5ms infinite;
         -o-animation: gogoloader-2 5ms infinite;
            animation: gogoloader-2 5ms infinite;
}

@-webkit-keyframes gogoloader {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 0;
    }
}

@-moz-keyframes gogoloader {
    0% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
             transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
             transform: scale(1.2);
        opacity: 0;
    }
}

@-o-keyframes gogoloader {
    0% {
        -webkit-transform: scale(0);
        -o-transform: scale(0);
           transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.2);
        -o-transform: scale(1.2);
           transform: scale(1.2);
        opacity: 0;
    }
}

@keyframes gogoloader {
    0% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
          -o-transform: scale(0);
             transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
          -o-transform: scale(1.2);
             transform: scale(1.2);
        opacity: 0;
    }
}

.deactivate span.web-inner {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(50px);
       -moz-transform: translateY(50px);
        -ms-transform: translateY(50px);
         -o-transform: translateY(50px);
            transform: translateY(50px);
    -webkit-transition: all 0.3s 0.5s ease-in-out;
    -o-transition: all 0.3s 0.5s ease-in-out;
    -moz-transition: all 0.3s 0.5s ease-in-out;
    transition: all 0.3s 0.5s ease-in-out;
}

.deactivate {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s 1s ease-in-out;
    -o-transition: all 0.3s 1s ease-in-out;
    -moz-transition: all 0.3s 1s ease-in-out;
    transition: all 0.3s 1s ease-in-out;
}


/* --- main-wrapper --- */
.main-wrapper{
    min-height: -webkit-calc(100vh - 45.5px - 105px);
    min-height: -moz-calc(100vh - 45.5px - 105px);
    min-height: calc(100vh - 45.5px - 105px);
}


/* --- Common max width --- */
.maxwidth1920{
    max-width: 1920px;
    margin: auto;
}

/* --- Bootsrep Overaid Css Start --- */
.container-lg.cl-custome{
    max-width: 1140px;
}
/* --- Bootsrep Overaid Css End --- */


/* --- common section background css start --- */
.common-bg-style{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.common-bg-style.cbs1{
    background-image: url("../img/bgimg/banner(04).webp");
}

.common-bg-color{
    border-radius: 20px;
    background: linear-gradient(137.45deg, #24262d, var(--dark));
}
/* --- common section background css end --- */

/* --- Social Share Icon & Social Icons start --- */
.social-icon{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 20px;
}

.social-icon{
    padding-bottom: 0;
}

.social-icon .si-item{
    padding: 0px 3px;
    text-align: center;
    cursor: pointer;
}

.social-icon .si-item .si-icon{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 40px;
    height: 40px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    overflow: hidden;
    margin: auto;
    font-size: 16px;
    color: var(--celeste-600);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.social-icon .si-item .si-icon::after{
    content: ""; 
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
       -moz-transform: translate(-50%, -50%) scale(0);
        -ms-transform: translate(-50%, -50%) scale(0);
         -o-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    z-index: 0;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.social-icon .si-item .si-icon::before{
    content: ""; 
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    border: 2px solid var(--dark3);
    -webkit-transform: translate(-50%, -50%) scale(1);
       -moz-transform: translate(-50%, -50%) scale(1);
        -ms-transform: translate(-50%, -50%) scale(1);
         -o-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    z-index: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.social-icon .si-item .si-icon .fa{
    width: 20px;
    height: 20px;
    position: relative;
    top: 0px;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.social-icon .si-item:hover .si-icon{
    color: var(--gold-light);
}


.social-icon .si-item:hover .si-icon::after{
    opacity: 1;
}

.social-icon .si-item:hover .si-icon::before{
    -webkit-transform: translate(-50%, -50%) scale(0);
       -moz-transform: translate(-50%, -50%) scale(0);
        -ms-transform: translate(-50%, -50%) scale(0);
         -o-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
}

.social-icon .si-item:hover .si-icon::after{
    background-color: var(--dark3);
    -webkit-transform: translate(-50%, -50%) scale(1);
       -moz-transform: translate(-50%, -50%) scale(1);
        -ms-transform: translate(-50%, -50%) scale(1);
         -o-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
}

/* .social-icon .si-item.fb-item .si-icon::after{
    background-color: #2645a9;
    background-image: -webkit-linear-gradient(45deg, #2645a9, #4e78ff);
    background-image: -moz-linear-gradient(45deg, #2645a9, #4e78ff);
    background-image: -o-linear-gradient(45deg, #2645a9, #4e78ff);
    background-image: linear-gradient(45deg, #2645a9, #4e78ff);
}

.social-icon .si-item.insta-item .si-icon::after{
    background-color: #dc2743;
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    background: -moz-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    background: -o-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
}

.social-icon .si-item.li-item .si-icon::after{
    background-color: #0077b5;
    background-image: -webkit-linear-gradient(45deg, #0077b5, #4e78ff);
    background-image: -moz-linear-gradient(45deg, #0077b5, #4e78ff);
    background-image: -o-linear-gradient(45deg, #0077b5, #4e78ff);
    background-image: linear-gradient(45deg, #0077b5, #4e78ff);
}

.social-icon .si-item.youtube-item .si-icon::after{
    background-color: #dd0021;
}

.social-icon .si-item.whatsapp-item .si-icon::after{
    background-color: #44be53;
    background-image: -webkit-linear-gradient(45deg, #09ad1d, #44be53);
    background-image: -moz-linear-gradient(45deg, #09ad1d, #44be53);
    background-image: -o-linear-gradient(45deg, #09ad1d, #44be53);
    background-image: linear-gradient(45deg, #09ad1d, #44be53);
} */

/* .social-icon .si-item.copy-item .si-icon::after,
.social-share-icon .ssi-item.copy-item .ssi-icon{
    background-color: var(--color5);
    background-image: linear-gradient(-45deg, var(--color5), var(--color6), var(--color7), var(--color8), var(--color9));
} */

/* --- Social Share Icon & Social Icons End --- */


/* --- common page bg bx Start --- */
.common-page-bg-bx{
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -5;
    overflow: hidden;
    pointer-events: none;
    transition: all 1s ease-in-out;
}

.common-page-bg-bx::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(5%, var(--dark)), color-stop(50%, var(--dark-600)), color-stop(95%, var(--dark)));
    background-image: -webkit-linear-gradient(bottom, var(--dark) 5%, var(--dark-600) 50%, var(--dark) 95%);
    background-image: -moz-linear-gradient(bottom, var(--dark) 5%, var(--dark-600) 50%, var(--darke) 95%);
    background-image: -o-linear-gradient(bottom, var(--dark) 5%, var(--dark-600) 50%, var(--dark) 95%);
    background-image: linear-gradient(0deg, var(--dark) 5%, var(--dark-600) 50%, var(--dark) 95%);
}

.common-page-bg-bx img{
    max-width: 1920px;
    width: 100%;
    height: 100%;
    margin: auto;
    display: block;
    transition: all 0.3s ease-in-out;
}

.common-page-bg-bx video{
    max-width: 1920px;
    width: 100%;
    margin: auto;
    display: block;
    transition: all 0.3s ease-in-out;
}

.cpbg-op0{
    opacity: 0;
}

.cpbg-op1{
    opacity: 1;
}
/* --- common page bg bx End --- */


/* --- Common Heading Bx Css Start --- */
.common-heading-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 10px;
}

.common-heading-bx.chb-border-bottom{
    padding-bottom: 10px;
    border-bottom: 1px solid var(--electricblue-400);
}

.common-heading-bx.chb-fd-row{
    gap: 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}

.common-heading-bx .small-lable{
    font-size: 14px;
    color: var(--celeste-500);
    font-weight: 500;
}

.common-heading-bx .common-heading{
    font-size: 24px;
    color: var(--celeste);
    font-weight: 600;
}

.common-heading-bx .common-heading.ch-big{
    font-size: 36px;
}

.common-heading-bx .common-heading .ch-color{
    color: var(--electricblue);
}

.common-heading-bx .common-heading .ch-color-gradient{
    background: -webkit-linear-gradient(182.25deg, var(--gold-light), var(--gold-dark));
    background: -moz-linear-gradient(182.25deg, var(--gold-light), var(--gold-dark));
    background: -o-linear-gradient(182.25deg, var(--gold-light), var(--gold-dark));
    background: linear-gradient(267.75deg, var(--gold-light), var(--gold-dark));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.common-heading-bx .common-heading .ch-size-big{
    font-size: 140%;
}

.common-heading-bx .common-sub-heading{
    font-size: 16px;
    color: var(--celeste-600);
}


@media screen and (max-width:768px) {
    .common-heading-bx .common-sub-heading br,
    .common-heading br{
        display: none;
    }

    .common-heading-bx.chb-fd-row{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: inherit;
        -webkit-justify-content: inherit;
           -moz-box-pack: inherit;
            -ms-flex-pack: inherit;
                justify-content: inherit;
        /* align-items: inherit; */
    }
}

@media screen and (max-width:992px) {
    .common-heading-bx .common-heading.ch-big{
        font-size: 32px;
    }
}

@media screen and (max-width:768px) {
    .common-heading-bx .common-heading.ch-big{
        font-size: 28px;
    }

    .common-heading-bx .common-heading{
        font-size: 20px;
    }

    .common-heading-bx .common-sub-heading{
        font-size: 14px;
    }
}

@media screen and (max-width:576px) {
    .common-heading-bx .common-heading.ch-big{
        font-size: 20px;
    }
}
/* --- Common Heading Bx Css Start --- */


/* --- Common Button Css Start --- */
.common-btn-item{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    height: 40px;
    padding: 0 15px;
    font-size: 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: var(--dark);
    font-weight: 600;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-btn-item::after{
    content: "";
    position: absolute;
    inset: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(var(--dark-600)));
    background-image: -webkit-linear-gradient(transparent, var(--dark-600));
    background-image: -moz-linear-gradient(transparent, var(--dark-600));
    background-image: -o-linear-gradient(transparent, var(--dark-600));
    background-image: linear-gradient(transparent, var(--dark-600));
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-btn-item span{
    white-space:nowrap;
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-btn-item img{
    position: relative;
    top: 0.5px;
    width: 18px;
    height: 18px;
    -o-object-position: center;
       object-position: center;
    -o-object-fit: contain;
       object-fit: contain;
    margin-left: 3px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-btn-item svg{
    width: 18px;
    height: 18px;
    margin-left: -18px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-btn-item:hover svg{
    margin-left: 8px;
    opacity: 1;
    visibility: visible;
}

.common-btn-item.cbi-fill{
    color: var(--dark);
    border-color: var(--gold-light);
    background: -webkit-gradient( linear, left top, right top, from(var(--gold-light)), to(var(--gold-dark)) );
    background: -webkit-linear-gradient( left, var(--gold-light), var(--gold-dark) );
    background: -moz-linear-gradient( left, var(--gold-light), var(--gold-dark) );
    background: -o-linear-gradient( left, var(--gold-light), var(--gold-dark) );
    background: linear-gradient( 90deg, var(--gold-light), var(--gold-dark) );
    background-size: 200% 100%;
    background-position: left center;
}


.common-btn-item.cbi-outline{
    color: var(--gold-light);
    border-color: var(--gold-light);
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-btn-item.cbi-outline .cbidata{
    position: absolute;
    inset: 0px;
    color: var(--dark);
    background: var(--gold-light);
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 0px;
       -moz-border-radius: 0px;
            border-radius: 0px;
    -webkit-transform: translateX(-100%);
       -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
         -o-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-btn-item.cbi-outline::after{
    display: none;
}

.common-btn-item.cbi-none{
    color: var(--electricblue);
}


@media only screen and (min-width: 768px) {

    .common-btn-item.cbi-fill:hover{
        background-position: right center;
    }

    /* .common-btn-item.cbi-fill:hover::after{
        opacity: 1;
        visibility: visible;
        background-position: left center;
    } */

    .common-btn-item.cbi-outline:hover span{
        -webkit-transform: translateX(-webkit-calc(100% + 20px));
           -moz-transform: translateX(-moz-calc(100% + 20px));
            -ms-transform: translateX(calc(100% + 20px));
             -o-transform: translateX(calc(100% + 20px));
                transform: translateX(calc(100% + 20px));
    }
    
    .common-btn-item.cbi-outline:hover .cbidata{
        -webkit-transform: translateX(0);
           -moz-transform: translateX(0);
            -ms-transform: translateX(0);
             -o-transform: translateX(0);
                transform: translateX(0);
    }

    .common-btn-item.cbi-none:hover img{
        -webkit-transform: translateX(5px);
           -moz-transform: translateX(5px);
            -ms-transform: translateX(5px);
             -o-transform: translateX(5px);
                transform: translateX(5px);
    }
    
}
/* --- Common Button Css End --- */


/* --- common date style css start --- */
.common-img-bx img{
    width: 100%;
}

.common-date-text{
    font-size: 18px;
    color: var(--celeste);
}

.common-date-text2{
    font-size: 16px;
    color: var(--celeste);
}

.common-date-ul{
    color: var(--celeste-600);
    padding: 0;
    list-style: none;
}

.common-date-ul li{
    position: relative;
    color: var(--celeste-600);
    padding: 5px 0px;
    padding-left: 20px;
}

.common-date-ul li::after{
    content: "";
    position: absolute;
    left: 2px;
    top: 12.3px;
    width: 8px;
    height: 8px;
    background-color: var(--cyan);
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
}
/* --- common date style css end --- */



/* --- Map Main Section Css Start --- */
.map-main-section.miness-margine{
    margin-bottom: -25%;
}

.map-main-section.top-miness-margine{
    margin-top: -10%;
}

.map-main-section img{
    width: 100%;
}
/* --- Map Main Section Css End --- */


/*  */
.about-img-section{
    background-color: var(--darkblue-700);
    height: 100%;
}

.about-img-section img{
    width: 100%;
    height: 100%;
}

@media screen and (max-width:768px) {
    .about-img-section img{
        max-width: 300px;
        height: 250px;
        margin: auto;
        display: block;
        padding: 0;
        -o-object-fit: cover;
           object-fit: cover;
        -o-object-position: center;
           object-position: center;
    }
}


/* -- FAQs Section Css Start --- */
.faqs-accordion{
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
    margin-top: 20px;
    padding: 10px 5px;
}

.faqs-accordion .acc-item{
    padding: 0 0;
    border-bottom: 2px solid var(--celeste-200);
}

.faqs-accordion .acc-item:last-child {
    border-bottom: none;
}

.faqs-accordion .acc-item .acc-heding{
    position: relative;
    font-size: 18px;
    padding: 15px 0px;
    padding-right: 30px;
    cursor: pointer;
    font-weight: 500;
    color: var(--celeste);
}

.faqs-accordion .acc-item .acc-heding.collapsed {
    opacity: 0.9;
}

.faqs-accordion .acc-item .acc-heding.collapsed:hover {
    opacity: 1;
}

.faqs-accordion .acc-item .acc-heding span.down-icon {
    position: absolute;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    background-image: url("../img/icons/Minus.svg");
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: contain;
       -moz-background-size: contain;
         -o-background-size: contain;
            background-size: contain;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 26px;
    height: 26px;
    font-size: 20px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}



.faqs-accordion .acc-item .acc-heding.collapsed span.down-icon {
    -webkit-transform: translateY(-50%) rotate(180deg);
       -moz-transform: translateY(-50%) rotate(180deg);
        -ms-transform: translateY(-50%) rotate(180deg);
         -o-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
    background-image: url("../img/icons/Plus.svg");
}

.faqs-accordion .acc-item .acc-heding span.down-icon.di-two {
    background-image: url("../img/icons/Minus2.svg");
}

.faqs-accordion .acc-item .acc-heding.collapsed span.di-two {
    background-image: url("../img/icons/Plus2.svg");
}

.faqs-accordion .acc-item .faqs-data{
    color: var(--celeste-700);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
}

.faqs-accordion .acc-item .faqs-data strong{
    color: var(--celeste);
}

.faqs-accordion .acc-item .faqs-data a{
    color: var(--celeste);
    text-decoration: underline;
}

.faqs-accordion  .acc-item .faqs-data a:hover{
    color: var(--electricblue);
}

@media screen and (max-width:576px) {
    .faqs-accordion .acc-item .acc-heding{
        font-size: 16px;
    }
}


/* --- accordion-white --- */
.accordion-white .acc-item{
    border-bottom-color: var(--darkblue-100);
}

.accordion-white .acc-item .acc-heding{
    color: var(--darkblue);
}

.accordion-white .acc-item .faqs-data{
    color: var(--darkblue-700);
    font-weight: 500;
}

.accordion-white .acc-item .faqs-data strong{
    color: var(--darkblue);
}

.accordion-white .acc-item .faqs-data a{
    color: var(--darkblue);
    text-decoration: underline;
}

.accordion-white .acc-item .faqs-data a:hover{
    color: var(--electricblue);
}

.accordion-white .acc-item .acc-heding span.down-icon.di-two{
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
}

/* -- FAQs Section Css End --- */


/* --- Contact Page Css Start --- */
.contact-info{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 10px;
    margin: 5px 0 !important;
    margin: 0;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    overflow: hidden;
}

.contact-info::after{
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    inset: 0;
    z-index: -1;
    opacity: 0;
    background: linear-gradient(137.45deg, #24262d, var(--dark));
    transition: all 0.3s ease-in;
}

.contact-info:hover::after {
    opacity: 1;
}

.contact-info .contact-icon {
    padding: 2px;
    width: 40px;
    height: 40px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 40px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 40px;
            flex: 0 0 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    opacity: 0.5;
}

.contact-info:hover .contact-icon {
    opacity: 1;
}

.contact-info .contact-icon img {
    width: 100%;
}

.contact-info .contact-text h6 {
    color: var(--celeste-600);
    font-weight: 500;
    font-size: 12px;
    line-height: 1.73;
    overflow: hidden;
    margin: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.contact-info .contact-text p {
    color: var(--celeste-800);
    font-size: 14px;
    margin: 0;
    font-weight: 500;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.contact-info:hover .contact-text p {
    color: var(--celeste);
    text-decoration: underline;
}
/* --- Contact Page Css End --- */


.clientsslider-bx .cs-item{
    width: 170px !important;
    height: 100px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    margin: 25px 10px;
    overflow: hidden;
    display: inline-block;
    border: 1px solid rgba(238, 238, 238, 0);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    /* opacity: 0.7; */
}

.clientsslider-bx .cs-item img{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -moz-transform: scale(1.1);
      -o-transform: scale(1.1);
         transform: scale(1.1);
}

.clientsslider-bx .cs-item:hover{
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    border-color: var(--electricblue-500);
    -webkit-box-shadow: 0 0 26px -10px var(--electricblue-500);
       -moz-box-shadow: 0 0 26px -10px var(--electricblue-500);
            box-shadow: 0 0 26px -10px var(--electricblue-500);
    opacity: 1;
}


/* --- Our Team Bx Start --- */
.our-team-card-item{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    gap: 15px;
    padding: 20px 0;
    border-bottom: 1px solid var(--darkblue-100);
}

.our-team-bx .our-team-card-item:last-child{
    border: none;
}

.our-team-bx .our-team-card-item:nth-child(odd){
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}

.our-team-bx .our-team-card-item:nth-child(even){
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
       -moz-box-orient: horizontal;
       -moz-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
}

.our-team-bx .our-team-card-item:nth-child(even) .otci-text{
    text-align: right;
}

.our-team-card-item .otci-img{
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 280px;
}

.our-team-card-item .otci-img img{
    width: 100%;
}

.our-team-card-item .otci-text{
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 5px;
    padding: 10px 0;
}

.our-team-card-item .otci-t-name{
    font-size: 24px;
    font-weight: 600;
    color: var(--darkblue);
}

.our-team-card-item .otci-t-position{
    font-size: 16px;
    font-weight: 500;
    color: var(--darkblue);
}

.our-team-card-item .otci-t-about{
    font-size: 16px;
    font-weight: 500;
    color: var(--darkblue-600);
}

@media screen and (max-width:576px) {
      
    .our-team-card-item .otci-img{
        max-width: 190px;
        width: 100%;
        height: 190px;
        -webkit-border-radius: 15px;
           -moz-border-radius: 15px;
                border-radius: 15px;
        overflow: hidden;
    }

    .our-team-card-item .otci-img img {
        width: 100%;
        height: 100%;
        -o-object-position: center;
           object-position: center;
        -o-object-fit: cover;
           object-fit: cover;
    }

    .our-team-card-item .otci-t-name{
        font-size: 18px;
    }

    .our-team-card-item .otci-t-about,
    .our-team-card-item .otci-t-position{
        font-size: 12px;
    }
}

@media screen and (max-width:450px) {       
    .our-team-card-item .otci-img{
        max-width: 150px;
    }
}

@media screen and (max-width:370px) {       

    .our-team-card-item .otci-img{
        max-width: 130px;
    }
}

/* --- Our Team Bx End --- */



.range-slider-bx{
    margin: 60px 0 25px;
}

.range-slider-bx *{
    -webkit-transition: none !important;
    -o-transition: none !important;
    -moz-transition: none !important;
    transition: none !important;
}

.range-slider-bx .input-range__label-container{
    position: relative;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    color: white;
    white-space: nowrap;
    padding: 5px 10px;
    font-weight: 500;
    font-size: 12px;
    border: 1px solid var(--cyan-200);
    background-color: var(--cyan-200);
    left: -45%;
    top: -21px;
    font-family: 'Montserrat';
    padding-right: 50px;
}

.range-slider-value{
    display: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    color: white;
    white-space: nowrap;
    padding: 5px 10px;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid var(--cyan-200);
    background-color: var(--cyan-200);
    margin-bottom: 15px;
    margin-right: -5px;
}

.range-slider-bx .input-range__label-container::after{
    content: "Client";
    position: absolute;
    right: 9px;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

.input-range__label.input-range__label--max,
.input-range__label.input-range__label--min{
    display: none;
}

.range-slider-bx .input-range__track--active{
    background-color: var(--electricblue);
}

.range-slider-bx .input-range__track{
    height: 4px;
}

.range-slider-bx .input-range__slider{
    background-color: var(--electricblue) !important;
    border-color: var(--electricblue) !important;
    width: 24px;
    height: 24px;
    margin-top: -0.9rem;
    margin-left: -0.8rem;
}

@media screen and (max-width:992px) {
    .range-slider-bx{
        padding: 0 8px;
    }
    .range-slider-bx .input-range__label-container{
        display: none;
    }

    .range-slider-bx{
        margin: 25px 0 25px;
    }

    .range-slider-value{
        display: block;
        margin-left: auto;
    }
}

/*  */


/* --- Close Btn Css Start --- */
.close-icon{
    position: absolute;
    right: 10px;
    top: 10px;
    width: 35px;
    height: 35px;
    background: transparent;
    -webkit-border-radius: 100%;
       -moz-border-radius: 100%;
            border-radius: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    color: var(--white);
    padding: 4px;
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.close-icon:hover{
    -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
         -o-transform: rotate(360deg);
            transform: rotate(360deg);
}

@media screen and (min-width:700px) {
    .close-icon:hover{
        background-color: var(--dark3);
    }
}

@media screen and (max-width:700px) and (min-width:0px) {
    .close-icon:active{
        background-color: var(--dark3)
    }
}
/* --- Close Btn Css End --- */


/* --- Overlaydiv Css Start --- */
.serach-overlay,
.overlaydiv {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    -webkit-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -moz-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out;
    top: 0;
    cursor: pointer;
    margin: 0 !important;
    z-index: 110;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.serach-overlay.active,
.overlaydiv.active {
    visibility: visible;
    opacity: 1;
}
/* --- Overlaydiv Css End --- */



.signupwhite.signup-bx{
    background-color: var(--white);
}




/*  ---- ----- */
.slidersidebox{
    position: relative;
}

.slidersidebox::before,
.slidersidebox::after{
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 14%;
    z-index: 1;
}

.slidersidebox::after{
    left: 0;
    background-image: -webkit-gradient(linear, left top, right top, from(var(--darkblue)), to(transparent));
    background-image: -webkit-linear-gradient(left, var(--darkblue), transparent);
    background-image: -moz-linear-gradient(left, var(--darkblue), transparent);
    background-image: -o-linear-gradient(left, var(--darkblue), transparent);
    background-image: linear-gradient(90deg, var(--darkblue), transparent);
}

.slidersidebox::before{
    right: 0;
    background-image: -webkit-gradient(linear, right top, left top, from(var(--darkblue)), to(transparent));
    background-image: -webkit-linear-gradient(right, var(--darkblue), transparent);
    background-image: -moz-linear-gradient(right, var(--darkblue), transparent);
    background-image: -o-linear-gradient(right, var(--darkblue), transparent);
    background-image: linear-gradient(-90deg, var(--darkblue), transparent);
}

@media screen and (max-width:1600px) {
    .slidersidebox::before,
    .slidersidebox::after{
        width: 12.5%;
    }
}

@media screen and (max-width:1200px) {
    .slidersidebox::before,
    .slidersidebox::after{
        width: 6%;
    }
}

@media screen and (max-width:992px) {
    .slidersidebox::before,
    .slidersidebox::after{
        width: 3%;
    }
}
/*  ---- ----- */


/* --- Breadcrumb Css Start --- */
.breadcrumb-bx{   
    display: -webkit-inline-box;   
    display: -webkit-inline-flex;   
    display: -moz-inline-box;   
    display: -ms-inline-flexbox;   
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    white-space: nowrap;
    width: 100%;
}

.breadcrumb-bx .breadcrumb-link{
    font-size: 14px;
    font-weight: 500;
    margin-right: 5px;
    color: var(--celeste-700);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.breadcrumb-bx .breadcrumb-link.breadcrumb-active{
    font-weight: 500;
    color: var(--celeste-400);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    pointer-events: none;
    text-decoration: none;
}

.breadcrumb-bx .breadcrumb-link:hover{
    color: var(--gold-light);
    cursor: pointer;
}


.lighttheme .breadcrumb-bx .breadcrumb-link{
    color: var(--darkblue-700);
    font-weight: 600;
}

.lighttheme .breadcrumb-bx .breadcrumb-link.breadcrumb-active{
    font-weight: 600;
    color: var(--darkblue-400);
}

.lighttheme .breadcrumb-bx .breadcrumb-link:hover{
    color: var(--electricblue);
}
/* --- Breadcrumb Css End --- */



.widthfull{
    max-width: 450px;
    width: 100%;
    margin: auto;
}

@media screen and (max-width:576px) {
    .width576full{
        width: 100%;
    }
}



/* --- Modals Common Css Start --- */
.modal{
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.modal.fade.zoom .modal-dialog{
    -webkit-transform: scale(0.9);
       -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
         -o-transform: scale(0.9);
            transform: scale(0.9);
}

.modal.zoom.show .modal-dialog {
    -webkit-transform: none;
       -moz-transform: none;
        -ms-transform: none;
         -o-transform: none;
            transform: none;
}

.modal-content.custom-content{
    background-color: var(--dark);
    background: linear-gradient(137.45deg, #24262d, var(--dark));
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.01) 0px 207px 58px, rgba(0, 0, 0, 0.08) 0px 132px 53px, rgba(0, 0, 0, 0.27) 0px 74px 45px, rgba(0, 0, 0, 0.45) 0px 33px 33px, rgba(0, 0, 0, 0.52) 0px 8px 18px, rgba(0, 0, 0, 0.53) 0px 0px 0px, rgba(255, 255, 255, 0.08) 0px 1px 1px inset, rgba(0, 0, 0, 0.25) 0px 0px 0px 1px inset;
       -moz-box-shadow: rgba(0, 0, 0, 0.01) 0px 207px 58px, rgba(0, 0, 0, 0.08) 0px 132px 53px, rgba(0, 0, 0, 0.27) 0px 74px 45px, rgba(0, 0, 0, 0.45) 0px 33px 33px, rgba(0, 0, 0, 0.52) 0px 8px 18px, rgba(0, 0, 0, 0.53) 0px 0px 0px, rgba(255, 255, 255, 0.08) 0px 1px 1px inset, rgba(0, 0, 0, 0.25) 0px 0px 0px 1px inset;
            box-shadow: rgba(0, 0, 0, 0.01) 0px 207px 58px, rgba(0, 0, 0, 0.08) 0px 132px 53px, rgba(0, 0, 0, 0.27) 0px 74px 45px, rgba(0, 0, 0, 0.45) 0px 33px 33px, rgba(0, 0, 0, 0.52) 0px 8px 18px, rgba(0, 0, 0, 0.53) 0px 0px 0px, rgba(255, 255, 255, 0.08) 0px 1px 1px inset, rgba(0, 0, 0, 0.25) 0px 0px 0px 1px inset;
}

.modal-content.custom-content.cc-style-2{
    background-color: var(--dark);
    background-image: none;
}

.custom-modal-header{
    position: -webkit-sticky;
    position: sticky;
    padding: 25px 15px 15px;
}

.custom-modal-header.cmh-px-50{
    padding: 25px 50px 15px;
}

.custom-modal-header .cmh-lable{
    font-size: 20px;
    color: var(--gold-light);
    font-weight: 600;
    text-align: center;
}

.custom-modal-header .cmh-sub-lable{
    font-size: 16px;
    color: var(--celeste-600);
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
}

.custom-modal-data *{
    font-size: 14px;
    color: var(--celeste);
}
/* --- Modals Common Css End --- */



/* --- App Store Icons Css Start --- */
.app-store-bx{
    margin-top: 25px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
            gap: 10px;
}

.app-store-bx .asb-item{
    padding: 3px;
    display: block;
    height: 50px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.app-store-bx .asb-item:hover{
    -webkit-transform: scale(1.05);
       -moz-transform: scale(1.05);
        -ms-transform: scale(1.05);
         -o-transform: scale(1.05);
            transform: scale(1.05);
}

.app-store-bx .asb-item img{
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
}
/* --- App Store Icons Css End --- */



/* --- Common Check list Css Start --- */
.check-list-bx{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 25px;
}

.check-list-bx .clb-item{
    align-items: center;
    color: var(--celeste);
    display: flex;
    font-size: 12px;
    gap: 5px;
}

.check-list-bx .clb-item .clb-item-icon{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: var(--gold-light);
    padding: 0px;
}
/* --- Common Check list Css End --- */


/* --- Store Section :- App Download Bx Start --- */
.app-download-bx{
    border-radius: 20px;
    background: url("../img/bgimg/world-bg.webp"), linear-gradient(137.45deg, #24262d, var(--dark)) ;
    background-position: center;
    background-repeat: no-repeat;
}

.app-download-bx .app-download-inner{
    width: calc(100% - 360px);
}

.app-download-bx > .adbbanner-img{
    position: absolute;
    right: 20px;
    bottom: 0;
    width: 325px;
    height: calc(100% + 30px);
    object-fit: contain;
    object-position: center;
}

@media screen and (max-width:768px) {
    .app-download-bx{
        background-position: center bottom;
        overflow: hidden;
    }

    .app-download-bx .app-download-inner{
        width: initial;
    }

    .app-download-bx > .adbbanner-img{
        position: relative;
        right: initial;
        bottom: -25px;
        width: 220px;
        height: 220px;
        margin-top: 25px;
    }
}
/* --- Store Section :- App Download Bx End --- */


/* --- Lagal Pages Css Start --- */
.legal-content .main-lable {
    font-weight: 600;
    margin-top: 30px;
    color: var(--celeste);
    font-size: 20px;
    text-transform: capitalize;
}

.legal-content .main-sub-lable {
    margin-top: 30px;
    font-weight: 500;
    color: var(--celeste);
    font-size: 18px;
    text-transform: capitalize;
    margin-bottom: 0px;
}

.legal-content ul li,
.legal-content p {
    font-size: 15px;
    line-height: 1.5;
    margin: 0;
    margin-top: 10px;
    color: var(--celeste-600);
    font-weight: 300;
    text-align: justify;
}

.legal-content ul {
    list-style: decimal;
    padding: 0 0 0 10px;
    margin-left: 20px;
    margin-top: 10px;
}

.legal-content ul.ul-dick {
    list-style: disc;
}

.legal-content ul.ul-alpha {
    list-style: lower-alpha;
}

.legal-content ul.ul-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

.legal-content ul.ul-flex li {
    width: 25%;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
       -moz-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    padding-right: 30px;
    text-align: left;
}

@media screen and (max-width:992px) {
    .legal-content ul.ul-flex li {
        width: 33.33%;
    }
}

@media screen and (max-width:768px) {
    .legal-content ul.ul-flex li {
        width: 50%;
    }
}

@media screen and (max-width:450px) {
    .legal-content ul.ul-flex li {
        width: 100%;
        padding-right: 0px;
    }
}


.legal-content ul li a,
.legal-content p a {
    text-decoration: underline;
    color: var(--celeste);
}

.legal-content ul a:hover,
.legal-content p a:hover {
    color: var(--gold-light);
}

.legal-content ul strong,
.legal-content p strong {
    color: var(--celeste);
}
/* --- Lagal Pages Css End --- */